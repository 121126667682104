.landing-page{
/* height:100vh; */
background-color: #3C91E6;


}

.landing-page h1{
    /* font-size: 2em; */
    /* margin-top: 1.5em; */
    /* margin-left: 1em; */
}

.landing-page p{
    /* margin-top: 1.5em; */
    /* margin-left: 2em; */
}

.landing-page button{
    /* margin-top: 1.5em; */
    /* margin-left: 2em; */
}