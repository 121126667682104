.LoginPage-Container{
    /* background-image: url("../../images/Orangevan.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat; */
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    align-items: center;
    justify-content: center;
    color:white;
}
.LoginPage {
    
    display: flex;
    flex-direction: column;
    margin: auto;
    /* height: 75vh; */
    /* width:75%; */
    max-width: 400px;
    margin:1em;
  
    background: rgba(0,0,0, 0.5);
    border-radius: .5em;
  
  }
  
  .LoginPage p {
    line-height:140%;
  }
  
  .LoginPage h2 {
      margin-top:10px;
    text-align: center;
    font-size:  2em;
    margin-bottom: 10px;
  }
  
  .LoginForm {
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .LoginForm form {
    margin: auto;
  }
  
  .LoginForm label {
    font-size: 18px;
  }
  
  .LoginForm input {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .LoginForm button {
    display: block;
    margin: auto;
    width: 30%;
    display: block;
    background-color: #D78423;
    margin: auto;
    font-size: 18px;
    padding: 6px;
    border-radius: 4px;
  }
  
  .LoginForm button:hover {
    background-color: #F1FFFA;
  }