.addTrip-Container {
    /* height:100vh; */
    background-color: #3C91E6;
}

.addTripPage {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 400px;
    background: rgb(59, 81, 132);
    border-radius: .5em;
}

.addTripPage h2 {
    text-align: center;
    font-size: 2em;
}

.edit-trip-form {
    margin: auto;
    color: black;
    width: 100%;
}

.edit-trip-form label {
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 16px;
    font-weight: 700;
    padding-right: 5px;
}

.edit-trip-form input[type="text"] {
    margin-bottom: 10px;
    width: 100%;
    font-size: 18px;
}