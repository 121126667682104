.cardContent form {
    margin-top:20px;
    clear:both;
    }
.cardContent label {
    margin-bottom: 5px;
    margin-left:5px;
    font-size: 16px;
    font-weight: 700;
    padding-right:5px;
}
.cardContent p {
    font-size:14px;
    margin-bottom: 5px;
    
  }
  label{
    font-size:14px;
    margin-bottom: 5px; 
  }
 input,  textarea{
    display: block;
      font-size: 16px;
      font-family: sans-serif;
      color: #444;
      line-height: 1.3;
      padding: 0.4em 1.4em 0.4em .8em;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      margin: 0;
      border: 1px solid #aaa;
      box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
      border-radius: 4px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;
  }