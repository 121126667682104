.trip-page-container {
    display: flex;
    flex-direction: column;
    /* height:100vh; */
}

.largeTripList {
    background-color: #2951AD;
    display: none;
    color: #F2F2F2;
}

.column50 {
    background-color: #3C91E6;
    flex: 3 1 auto;
    height: auto;
}

.action-buttons {
    /* display:block;
    margin-left: 250px; */
    display: flex;
    float: right;
    padding-left: 2em;
    flex: 1;
    justify-content: flex-end;
}

.action-buttons button {
    /* margin-top: 10px; */
    /* margin-left: 1em; */
    /* margin-right: 0.5em; */
}

.title-container {
    background-color: rgb(59, 81, 132);
}

.title {
    flex: 2;
    /* background-color:rgb(59, 81, 132); */
}

.title h2 {
    font-size: 1.5em;
    color: #F2F2F2
}

.welcome {
    /* height:100vh; */
}

.addPlace {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 700px) {
    .trip-page-container {
        display: flex;
        flex-direction: row;
        background-color: #2951AD;
        height: auto;
    }
    .tripList {
        max-width: 30%;
    }
    .tripList-select {
        display: none
    }
    .largeTripList {
        background-color: #2951AD;
        display: block;
        /* height:100vh; */
    }
    .column50 {
        height: auto;
    }
    .largeTripList a {
        text-decoration: none;
        color: #F2F2F2;
        display: block;
        margin-bottom: .5em;
        word-wrap: break-word;
    }
    .largeTripList a:visited {
        text-decoration: none;
    }
    .largeTripList a:hover, .active a {
        background-color: #D78423;
        text-decoration: underline;
    }
    .largeTripList a:active {
        background-color: #D78423;
    }
}