.placeCard{
    /* margin-left: 10%;
    margin-right: 10%; */
    margin-bottom: 1.5em; 
    background-color: #A4CFE9;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;
border-radius: 5px;
}
.placeCard:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.placeCard h2{
    font-size: 1.5em;
}