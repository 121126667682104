.nav-bar{
    background: #4381C1;
    /* height:10vh; */
    /* display:flex;
    flex-direction:row;
    justify-content: left; */
    
    
}
.nav-bar ul li{
    display: inline-block;
    text-align: center;
    padding: 20px;
    height: 2%;
   
}
.nav-bar a{
    text-decoration: none;
    color:black;
}
.nav-bar a:visited{
    text-decoration: none;
}
.nav-bar a:hover{
    background-color:#C9DDFF;
    text-decoration: underline;
}
.nav-bar .active a{
    background-color:#C9DDFF;
    text-decoration: underline; 
}